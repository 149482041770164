<template>
  <div class="users-wrapper height100percent">
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>